
// .p-detail-flex.inventory.view-inventory {
//   .p-detail-toolbar {
    button.btn-header {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      height: 40px;
      width: 160px;
      text-transform: uppercase;
      border-radius: 6px;
      box-shadow: 0 1px 8px 0 #888888;
      box-sizing: border-box;
      outline: none!important;
      border: 1px solid #ffffff;
      line-height: 1.2;
    }

    button.btn-header.primary {
      background-color: #d5239e;
      color: #fff !important;
    }

    button.btn-header.secondary {
      background-color: #fffbff;
      color: #d5239e;
      border: 1px solid #d5239e;
    }

    button.btn-header.danger {
      background-color: #d3103f;
      color: #fff;
    }

    button.disabled, .MuiButtonBase-root.Mui-disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    .right {
      display: flex;
      align-items: center;
      button {
        margin-right: 10px;
      }

      button:nth-of-type(2) {

      }

      button:last-child {
        margin-right: 0;
      }
    }
  // }
// }
