.apply-modal {
  .modal-header {
    padding: 0 24px;
    > h5 {
      font-size: 1.7em;
      font-weight: 600;
    }
  }

  .modal-body {
    .container {
      display: flex;
      justify-content: space-around;
      flex-direction: row;
    }

    .shipping-type {
      .form-group {
        display: flex;
        justify-content: flex-start;
        margin-left: 20px;
        margin-bottom: 0;
      }
    }
  }
}

.modal-open {
  .MuiTooltip-popper {
    z-index: 1;
  }
}