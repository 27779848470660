
.p-detail-flex.inventory.view-inventory, .p-detail-flex.connect {
  .MuiTabs-root {
    .MuiButtonBase-root.MuiTab-root.Mui-selected {
      color: $theme-primary;
      outline: none;
    }
    .MuiTabs-indicator {
      background-color: $theme-primary;
    }
  }
}

.p-detail-flex.inventory.addItem {
  #add-item-tab{
    &__0, &__1 {
      &:focus {
        outline: none;
      }
    }
  }
}