@import '../../assets/scss/copyt-platform-ui/custom/theme';

.MuiSvgIcon {
  &-colorWhite {
    color: $theme-white;
  }
  &-colorBlack {
    color: $theme-black;
  }
  &-colorDanger {
    color: $theme-danger;
  }
}
