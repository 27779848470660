@import '../../assets/scss/copyt-platform-ui/custom/theme';


.MuiButtonBase-root button {
  outline: 0;
}

// todo: make sure theming will not affects other elements
// .MuiButton-textPrimary {
//   color: $theme-primary !important;
// }

.datagrid-row {
  .MuiButton {
    &-root {
      padding: 4px 10px;
      border-radius: 4px;
    }
    &-containedSuccess {
      background-color: $theme-success;
      color: $theme-white;
    }
    &-containedError {
      background-color: $theme-danger;
      color: $theme-white;
    }
    &-containedSecondary {
      background-color: $theme-button-secondary;
      color: $theme-white;
    }
    &-outlinedSuccess {
      color: $theme-success;
      border-color: $theme-success;
    }
    &-outlinedSecondary {
      color: $theme-button-secondary;
      border-color: $theme-button-secondary;
    }
    &-outlinedError {
      color: $theme-danger;
      border-color: $theme-danger;
    }
    &-outlinedPrimary {
      color: $theme-primary;
      border-color: $theme-primary;
    }
    &-noEvent {
      pointer-events: none;
      cursor: pointer;
    }
    &-borderSolid {
      border: 1px solid;
    }
  }
}

.MuiStack-breadcrumbs {
  .watch-tutorial { color: var(--primary); cursor: pointer; }
  .MuiBreadcrumbs-li, .MuiBreadcrumbs-separator {
    color: #140010cc;
    a, p { font-size: 0.9rem; }
    p { cursor: default; }
  }
  
  .MuiBreadcrumbs-li {
    &:hover {
      cursor: pointer;
      color:  var(--primary);
    }
  }
}

.MuiTabs-root .MuiButtonBase-root.MuiTab-root.Mui-selected {
  color: var(--primary);
}

.MuiTabs-indicator  {
  background-color: var(--primary) !important;
}


