.numeric-input {
  padding: 5px;
  border: 0;
  width: 90%;

  &-consign {
    padding: 8px 18px 8px 18px;
    border-color: #160012;
    border-radius: 6px;
    border: 1px solid black;
    width: 100%;
  }

  &-error {
    border-color: #d3103f;
  }
}