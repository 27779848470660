@import 'assets/scss/copyt-platform-ui/custom/theme';

.p-detail-flex.consignment {
  .header-summary {
    padding: 10px;
    color: #fff;
    background-color: $theme-primary;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .display-justify-end {
      display: flex;
      justify-content: flex-end;
    }

    .withdraw-btn {
      border-radius: 8px;
      border: 1px solid;
      width: 100px;
      background-color: $theme-white;
      color: $theme-primary;
      box-shadow: 0px 0px 3px 1px $theme-white;
      font-weight: 600;
    }
  }
}

.wrapper.applications-on-enterprise, .wrapper.applications-on-individual {
  .enterprise {
    .card-body {
      flex: 1;
      .MuiButton-outlined {
        border: 1px solid;
      }
      .form-group {
        .react-tel-input.consignment {
          .flag-dropdown { display: none; }
          .form-control { padding-left: 0; border: none; background-color: transparent; }
        }
      }
    }
  }
}

.img-overlay-wrap.no-image {
    height: 50px;
  }

  .img-overlay-wrap {
    position: relative;
    display: inherit;
  }

  .img-overlay-wrap img {
    display: block;
    max-width: 100%;
    height: 50px;
  }

  .img-overlay-wrap svg {
    position: absolute;
    top: 0;
    left: 0;
    height: 90px !important;
  }