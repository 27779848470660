.create-batch-modal {
  .modal-content .modal-body {
    .fee_settings_modal__heading {
      text-align: left;
    }
    .modal-body {
      padding: 0;
      button .my-4.btn-secondary {
        margin: 1rem 0;
      }
    }
  }
}

.create-batch-modal {
  .modal-content .modal-body {
    .btn-header.secondary {
      margin-top: 30px;
      left: 35%;
    }
  }
}