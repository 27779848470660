@import '../../../../assets/scss/copyt-platform-ui/custom/theme';

.p-detail-flex.connect {
  .p-panel-master.no-print {
    width: 320px;
    .cTL {
      .MuiSwitch-root {
        .MuiButtonBase-root {
          padding: 9px;
          position: absolute;
        }
      }
    }
    .master-table.isEmpty {
      .table-body {
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
    }
    .master-table {
      height: 95%;
      .table {
        &-head {
          padding: 10px 10px 15px;
          border-bottom: 1px solid var(--gray-light);
  
          div:first-child {
            padding-bottom: 10px;
            font-weight: 600;
            font-size: 1.1em;
          }
          div:last-child {
              padding: 10px;
              border: 1px solid var(--gray-light);
              background-color: white;
              border-radius: 5px;

              button {
                float: right;
                border: 0;
                background: transparent;
                padding: 0;
              }
          }
        }
        &-footer {
          .add-contacts {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 5px 10px;
            background-color: white;
          }
          button {
            border: 0;
            background: transparent;
          }
          .search-item {
            height: 38px;
            margin: 5px;
          }
        }
        &-body {
          .contact {
            &-row {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 10px;
              border-bottom: 1px solid var(--gray-light);

              .oEm {
                width: 250px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
    }
  }

  .p-detail-toolbar.no-print.v2 {
    display: flex;
    justify-content: flex-end;
  }
  

  .empty-state-card {
    height: 100%;
  }

  .MuiBox-root.inventory-container.connect-table {
    .MuiDataGrid {
      &-root {
        .MuiButtonBase-root {
          font-weight: 700;
          color: $theme-primary;
        }
      }
    }
  }
}

