.paywall-modal {
  .modal-body {
    padding: 0;
    svg {
      margin-top: 10px;
      color: black;
    }
    .modal-close {
      border: none;
      background-color: transparent;
      box-shadow: none;
    }
  }
}
