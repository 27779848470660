.table_th {
  padding: 20px 20px;
  position: relative;
  white-space: nowrap;
  transition: all 0.2s ease-in-out;
  background-color: #ffd0fa;
  position: sticky;
  top: 0;
  z-index: 10;

  &:hover {
    background-color: #ffd0fa90;
  }

  &__content {
    color: #5d0953;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    svg {
      margin-top: 3px;
    }
  }
}
