@import '../../../../../../assets/scss/copyt-platform-ui/custom/theme';

@font-face {
  font-family: 'Azonix';
  src: url(../../../../../../assets/fonts/Azonix.ttf) format('truetype'),
    url(../../../../../../assets/fonts/Azonix.svg) format('svg'), url(../../../../../../assets/fonts/Azonix.woff) format('woff'),
    url(../../../../../../assets/fonts/Azonix.eot) format('embedded-opentype');
}

.premium-modal.standard {
  .modal-content {
    height: 80%;
  }
}

.premium-modal {
  height: calc(100% - 3rem);
  .modal-body {
    padding: 0;
  }
  .modal-content {
    background-image: url('../../../../../../assets/img/Shoes.png'),
      linear-gradient(180deg, rgba(213, 35, 158, 0.12) 0%, rgba(255, 255, 255, 0.8) 100%);
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100% 100%;
    border-radius: 6px;
    background-color: $theme-primary;;
    height: 100%;
    box-shadow: none;
    padding: 24px;
    .premium-close {
      width: 45px;
      border: none;
      background: none;
    }

    .white-bubble {
      background-color: #fffbff;
      border-radius: 24px;
      box-shadow: 0px 1px 8px 0px #1400103d;
      margin-top: 130px;
      padding: 12px 16px;
    }
    .modal-gradient {
      background: linear-gradient(180deg, rgba(255, 208, 250, 0) 0%, rgba(93, 9, 83, 0.48) 100%);
      height: 100%;
      width: 100%;
    }

    .modal-header {
      padding: 0;
    }

    .modal-footer {
      position: absolute;
      padding: 0 !important;
      justify-content: center;
      bottom: 0;
      width: 100%;
    }

    .premium-modal-title {
      font-family: 'Azonix';
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      line-height: 35px;
      text-align: center;
      color: $theme-primary;
      text-shadow: -4px -3px #ffd0fa;
    }

    .premium-benefits {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #140010;
      display: flex;
      flex-direction: column;

      .premium-benefits-item {
        display: flex;
        flex-direction: row;
        padding-top: 10px;
        padding-left: 15px;
      }

      .premium-benefits-description {
        color: #140010;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 16px;
        text-align: center;
        margin-top: 8px;
        margin-left: 16px;
        margin-right: 16px;
        .premium-benefits-description-link {
          color: $theme-primary;
        }
      }

      .premium-benefits-text {
        color: #140010;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 15px;
        text-align: left;
      }

      .premium-icon {
        margin-right: 12px;
        height: 48px;
        width: 48px;
        min-height: 48px;
        min-width: 48px;
        margin-top: 5px;
      }
    }

    .premium-advert {
      border: 2px solid #140010;
      border-radius: 50%;
      height: 198px;
      width: 198px;
      text-align: center;
      overflow: hidden;
      vertical-align: middle;
      display: table-cell;
      background-color: #fffbff;
      font-weight: 700;
      font-size: 18px;
    }

    .premium-price {
      color: $theme-primary;
    }

    .premium-button {
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      height: 40px;
      text-transform: uppercase;
      border-radius: 6px;
      background-color: $theme-primary;
      color: $theme-white;
      border: none;
      box-sizing: border-box;
      cursor: pointer;
      width: 100%;
      margin-top: 16px;
      box-shadow: 1px 2px #8d7a7aee;
      border: 1px solid;
    }

    .premium-button.skip-paywall {
      width: 20%;
      margin: 16px 10px 0;
      background: $theme-danger;
      box-shadow: 1px 2px #8d7a7aee;
      border: 1px solid;
    }

    .paywall-footer {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      flex-wrap: nowrap;
      align-items: center;
    }
    .skip {
      margin-top: 16px;
    }
  }
}

// start of paywall
.paywall-wrapper {
  background-image: url("../../../../../../assets/img/copyt-welcome.jpg");
  background-size: 100% 100%;
  height: 95vh;
  background-repeat: no-repeat;

  &--body {
    padding: 160px 100px;


    [data-testid="PublishedWithChangesIcon"] {
      font-size: 3em;
      color: #d5239e;
      margin: 15px 0;
      width: 100%;
    }
    
    .paywall-tab.MuiBox-root {
      margin-bottom: 30px;
      display: flex;
      justify-content: center;
      
      .MuiTabs-root {
        background-color: #d5239e;
        border-radius: 5px;
        width: 98%;
  
        .MuiTabs-scroller.MuiTabs-fixed {
          height: 50px;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
  
          .MuiTabs-flexContainer {
            height: 50px;
  
            button.MuiButtonBase-root {
              min-height: 40px;
              color: $theme-white;
              font-weight: 600;
            }
            button.MuiButtonBase-root.Mui-selected {
              color: $theme-primary;
              background-color: $theme-white;
              border-radius: 10px;
              margin: 5px;
              outline: 5px auto -webkit-focus-ring-color;
            }
          }
          span.MuiTabs-indicator {
            background-color: transparent;
          }
        }
      }
    }
    
    .welcome {
      &-title {
        text-align: center;
        font-size: 1.4em;
        font-weight: 600;
        text-shadow: 2px 2px #fee8ff;
      }
      &-subtitle {
        text-align: center;
        font-size: 1.2em;
      }
      &-body {
        text-align: justify;
        font-size: 1.1em;
      }

      &-step-container {
        border-radius: 10px;
        background-color: $theme-white;
        min-height: 230px;
        max-height: 530px;
        overflow-y: auto;

        button { margin-bottom: 10px; }
        button:first-child { margin-left: 20px; }
        button:last-child { margin-right: 5px; }

        .container {
          overflow-y: auto;
          max-height: 50vh;
        }
      }

      &-success svg {
        font-size: 3.5em;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 10px;
        color: $theme-primary;
      }
    }

    .MuiStepLabel-root {
      .MuiStepIcon-root.Mui-active, .MuiStepIcon-root.Mui-completed { color: $theme-primary; }
    }

    .MuiButton-textPrimary {
      color: $theme-primary;
    }

    .actions-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;

      .begin {
        font-style: italic;
        margin-bottom: 10px;
      }

      div.or {
        font-size: 1.1em;
        font-weight: 600;
      }

      > button {
        width: 80%;
        border-radius: 10px;
      }
      > button.btn-primary {
        border: 1px solid $theme-white;
      }
    }
  }
}