.inventory-details {
  .MuiGrid-root.grid-invntry {
    margin-bottom: 8px;
    .form-group {
      margin-bottom: 0;
    }
  }

  .card-body.simplified {
    min-height: 530px;
  }
}

  .card-list-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
