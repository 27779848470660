@mixin icon-shape-variant($color) {
    color: saturate(darken($color, 10%), 10);
    background-color: transparentize(lighten($color, 10%), .5);
}

.import-button {
    color: rgb(200,205,215);
    height: 40px;
    width: 40px;
    min-height: 40px;
    min-width: 40px;
    align-self: center;
    margin-right: 8px;
    margin-left: 8px;
    cursor: pointer;
    padding: 10px;
}

.button-spinner {
    position: absolute;
    margin: 0;
    top: 0;
}