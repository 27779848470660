.wrapper.applications-on-enterprise,
.wrapper.applications-on-individual,
.wrapper.pending-codes,
.wrapper.claimable-items,
.wrapper.batches {
  .p-panel-detail {
    display: flex;
    justify-content: center;
  }
  .p-detail-tab-wrapper__panel {
    width: 100%;
    max-width: 1800px;
  }
}
