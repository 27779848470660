@import "theme";

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 8px;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0.25rem;
}

.breadcrumb-item {
  font-size: 16px;
  font-weight: 500;
  color: $theme-text-primary;
  outline: none;
}

.breadcrumb-item > a {
  outline: none;
  color: $theme-text-primary;
  cursor: pointer;
  font-weight: 500;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: $theme-black;
  content: ">";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }
