.notice-modal {
  .modal-content {
    .modal-title {
      .modal {
        &__heading h1 {
          text-align: left;
          }
        }
      }
      .modal-body {
        .modal-body {
          padding: 0;
          .audit-section {
            .verification {
              .item-description {
                margin: 10px 0;
                border: 1px solid #eee;
                border-radius: 5px;
                padding: 10px;
                text-align: center;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;

                div:first-child {
                  display: flex;
                  margin-right: 10px;
                }

                &-info {
                  display: flex;
                  justify-content: space-between;
                }
              }
              .warning {
                color: red;
                font-style: italic;
              }
            }
            svg {
              font-size: 50px;
              margin-right: 10px;
            }
            span {
              display: flex;
              flex-direction: column;
              justify-content: center;
            }
          }

          .delete-btn {
            display: flex;
            justify-content: space-evenly;
            margin: 30px auto 20px;
          }
        }
      }
    }
}