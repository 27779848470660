.skeleton-wrapper {
  position: relative;
  overflow: hidden;
}

/* themes */
.skeleton-wrapper.primary {
  background: #d5239e;
}
.skeleton-wrapper.secondary {
  background: #ffd0fa;
}
.skeleton-wrapper.foreGray {
  background: #00000005;
}

/* animation effects */

.shimmer-wrapper {
  position: absolute;
  /* parent must be relative position & overflow hidden */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: loading 2.5s infinite;
}
.shimmer {
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: skewX(-20deg);
  box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.2);
}
.dark .shimmer {
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.05);
}

.light.shimmer {
  background: rgba(235, 235, 235, 0.2);
}

@keyframes loading {
  0% {
    transform: translateX(-150%);
  }
  50% {
    transform: translateX(-60%);
  }
  100% {
    transform: translateX(150%);
  }
}
