@import '../../../../assets/scss/copyt-platform-ui/custom/theme';

.p-detail-flex.inventory.view-inventory {
  .empty-state-card {
    height: 100%;
  }
  .MuiBox-root.inventory-container.unlisted-table {
    .MuiDataGrid {
      &-root {
        .MuiButtonBase-root {
          font-weight: 700;
          color: $theme-primary;
        }
      }
    }

    .search-toolbar {
      display: flex;
      justify-content: space-between;
      background-color: #ffbeeb36;
      border-bottom: 1px solid #a5709b;

      .search-item {
        height: 38px;
        width: 250px;
        margin-top: 5px;
        margin-right: 5px;
      }
    }
  }
  .p-detail-toolbar.no-print.v2 {
    justify-content: end !important;
  }

  .img-overlay-wrap.no-image {
    height: 50px;
  }

  .img-overlay-wrap {
    position: relative;
    display: inherit;
  }

  .img-overlay-wrap img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  .img-overlay-wrap svg {
    position: absolute;
    top: 0;
    left: 0;
    height: 90px !important;
  }
}
