@import '../../../../assets/scss/copyt-platform-ui/custom/theme';

.p-detail-flex.connect {
  .add-user-disabled {
    opacity: 0.3;
  }
  .p-panel-detail {
    left: 320px;
    height: 95%;
    .p-detail-tab-wrapper {
      &__panel {
        padding: 5px;
      }
    }
  }

   

  .p-wrapper.collapsed {
    .p-panel-detail {
      left: 0 !important;
    }
  }
  .table-product-connection-row {
    .MuiTableCell-root {
      color: var(--primary);
      background-color: var(--primary-light);
    }
  }
}
 