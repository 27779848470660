$p-assets-img-path: "../../../assets/img";

body { touch-action: pan-x pan-y; }
.wrapper.profile {
  .p-panel-detail {
    overflow-y: hidden;
  }
}

.p-detail-flex.profile {
  .p-detail-toolbar.no-print {
    .btn-save-position {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: 30px;
      justify-content: flex-end;
      flex: 1;
    }
  }

  .p-detail-tab-wrapper__panel.scrollable {
    overflow-y: auto;
    margin-bottom: 0;
    height: 90vh;
  }

  .react-tel-input .country-list {
    width: 300px;
    max-height: 130px;
    border: 1px solid #160012;
  }

  .react-tel-input  {
    .form-control {
      width: 100%;
      border-color: #160012;
      padding-left: 48px;
    }
    .flag-dropdown {
      border-color: #160012;
    }
  }

  .profile-big-card, .profile-big-card-skeleton {
    flex: 1;
    background-image: url('#{$p-assets-img-path}/Sneaker illustration.svg') !important;
    background-repeat: no-repeat !important;
    background-position-x: right !important;
    background-position-y: bottom !important;
    padding: 24px;
    display: flex;
    flex-direction: column;
  }
}
