@import '../../../../assets/scss/copyt-platform-ui/custom/theme';

.p-detail-flex.inventory.view-inventory {
  .p-detail-toolbar.no-print.v2 {
    display: flex;
    justify-content: flex-end;
  }

  .inventory-table-toolbar {
    padding: 0 4px;

    .search-item {
      height: 38px;
      width: 250px;
    }
    button.options-btn {
      background-color: transparent;
      font-weight: 700;
      color: $theme-primary;
      

      &:hover,
      &:focus,
      &:active {
        border: 0;
        outline: 0;
      }
    }
  }
}

#customized-menu {
  .grid-export-btn {
    color: #140010;
    text-transform: capitalize;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0.00938em;
    line-height: 1.5;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    > span {
      margin-right: 0;
    }
  }

  .options-list {
    display: flex;
    flex-direction: column;
    
    > li {
      justify-content: flex-start;
      padding: 4px 10px;
    }
  }
}

.consigned-toggle {
  .MuiButtonBase-root.MuiSwitch-switchBase {
    padding: 9px !important;
    border-radius: 50%;
    position: absolute;
  }
}