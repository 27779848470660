@import '../../../assets/scss/copyt-platform-ui/custom/theme';
@import './component/scss/modal-content.scss';

.p-detail-flex.account-settings {
  .hide,
  .hidden-panel {
    display: none;
  }
  .p-detail-toolbar {
    padding: 10px 19px;

    .segments {
      .css-iajp3t-MuiButtonGroup-root .MuiButtonGroup-grouped:hover {
        background-color: $theme-primary;
        color: $theme-white;
      }
      .active {
        background-color: $theme-primary;
      }
    }
  }

  .p-breadcrumb {
    flex: 0 1 auto;
    padding: 5px 20px;
    border-bottom: solid 1px #dddddd;
    background-color: $theme-white;

    .breadcrumb {
      margin: 0;
      padding: 0;

      &-item {
        font-size: 1em;
      }

      &-item.active > a {
        color: $theme-primary;
      }
    }
  }

  .p-detail-tab-wrapper__panel.scrollable {
    position: relative;
  }

  .p-detail-content {
    .login-details-container,
    .profile-settings-container,
    .store-settings-container {
      padding: 0px 24px;
    }
  }

  .segments {
    .Consignment.Management.show {
      border: 1px solid #9c27b0 !important;
    }

    .Pending.Codes.show,
    .Listing {
      border-right: 1px solid #9c27b0 !important;
      border-top-right-radius: 5px !important;
      border-bottom-right-radius: 5px !important;
    }
  }
}

.account-big-card,
.account-big-card-skeleton {
  flex: 1;
  background-image: url('../../../assets/img/AccountSettingsImage.svg') !important;
  background-repeat: no-repeat !important;
  background-position-x: right !important;
  background-position-y: bottom !important;
  padding: 24px;
  margin-top: 24px;
}

.account-big-card {
  .section-first {
    margin-top: 8px;
  }

  .fee-settings,
  .description-template-card {
    .card-title {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .btn-tertiary.upload-btn.fee {
      margin-top: 5px;
    }
  }

  .btn-tertiary.upload-btn {
    margin-bottom: 4px;
    margin-top: 16px;
    margin-left: auto;
    margin-right: 0px;
    display: block;
    padding: 8px 16px;
    height: unset;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.24);
    border-radius: 6px;
  }

  .btn-tertiary.upload-btn.update-btn {
    width: 100px;
  }

  .btn-tertiary.upload-btn.btn-empty-state {
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 48px;
  }

  .custom-checkbox-label {
    /* 6px because the formgroups come with 10px margin-bottom.  10+6=16 */
    margin-top: 6px;
    margin-bottom: 6px;
  }
}

.empty-state-card .empty-state-body.empty-state-body-payment-details {
  max-width: 288px;
}

.show-mobile {
  display: none !important;
}

.hide-mobile {
  display: block !important;
}
@media (max-width: 992px) {
  .account-big-card {
    padding: 24px 0;
    .row.max-height > .col-lg-9 > .row > .col-lg-6 {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.max-height {
  height: 100%;
}

.cc-input {
  border: 1px solid #140010;
  box-sizing: border-box;
  border-radius: 6px;
  height: 40px;
  padding: 12px;
}

.cc-input-error {
  border: 1px solid #d3103f;
  box-sizing: border-box;
  border-radius: 6px;
  height: 40px;
  padding: 12px;
}

.credit-card-modal {
  width: 478px;
  border-radius: 4px;
  .modal-header {
    padding: 15px 24px 0 24px;
  }
  .subtitle {
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.10625px;
    color: #61375c;
    margin-top: 10px;
    margin-bottom: 15px;
  }
  .container {
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 24px;

    .btn-primary,
    .btn-secondary {
      flex: 1;
      margin-top: 24px;
    }
    .btn-primary {
      margin-left: 8px;
      margin-right: 0;
    }
    .btn-secondary {
      margin-right: 8px;
      margin-left: 0;
    }
  }

  .modal-content {
    height: 100%;
  }

  .modal-body {
    padding: 0px;
  }
  .modal-title {
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: #61375c;
  }

  .error-message {
    display: block;
    color: rgba(174, 10, 0, 1);
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.075px;
    color: #ae0a00;
    mix-blend-mode: normal;
    opacity: 0.8;
    margin: 4px;
  }

  .hidden {
    display: none;
  }
}

@media (max-width: 641px) {
  .account-big-card {
    .card:first-of-type {
      border-top: none !important;
      background-color: none;
    }
  }
  .account-big-card {
    margin-top: 0px;
    box-shadow: none !important;
    padding: 0;
    width: calc(100% + 30px) !important;
    margin-left: -15px !important;
    margin-right: -15px !important;
    .card {
      margin-bottom: 0;
      box-shadow: none;
      border-top: solid 1px #5d0953;
      border-radius: 0px;

      .card-header .card-title {
        font-style: normal;
        font-weight: 500 !important;
        font-size: 16px !important;
        line-height: 19px;
        letter-spacing: 0.075px;
        color: #140010 !important;
      }

      .col-lg-5 {
        padding-right: 0px !important;
      }

      .btn-tertiary.upload-btn {
        width: 100%;
      }
    }
  }

  .show-mobile {
    display: flex !important;
    margin-left: -30px;
    width: calc(100% + 30px) !important;
    margin-top: 16px;
  }

  .hide-mobile {
    display: none !important;
  }

  .modal-open .modal {
    overflow-y: auto !important;
  }
}
