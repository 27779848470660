.sticky-table {
    width: 100%;
    thead,
    tbody,
    tr {
        display: table;
        width: 100%;
        table-layout: fixed;
    }

    tr.inventory-header-tr {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        flex-wrap: nowrap;
    }

    tbody {
        display: block;
        overflow: auto;
        table-layout: fixed;
        max-height: 700px;
    }
}

.sticky-table.inventory-page {
    display: flex;
    flex-direction: column;

    thead.text-primary {
        display: flex;

        .inventory-header-tr {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: flex-start;
            text-align: center;

            > th {
                flex: 1;
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: space-between;
                height: 100%;

                > button {
                    padding: 0;
                }
            }

            >th.inventory-header-th.checkbox {
                visibility: hidden;
            }
            
        }
    }

    tbody {
        .inventory-item-tr.listed-individual {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            text-align: center;
        }

        .inventory-item-tr {
            text-align: center;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            text-align: center;
            align-items: center;
            height: auto !important;
            max-height: unset;

            cursor: pointer;
            > td {
                flex: 1;
            }

            > td.Email {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .listed-checkbox {
                .form-group {
                    display: flex;
                    flex-direction: column-reverse;
                    align-items: center;
                    margin-bottom: 0;

                    > label > span {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        align-items: center;
                    }
                }
            }
        }

        .listing-job {
            width: 100%;
        }
    }
}

.tim-icons {
    color: #c8cdd7;
    margin-bottom: 4px;
}

#list-all-btn {
    margin-left: 16px;
    width: 90px;
}

#list-all-btn:hover {
    background-color: #d5239e;
    color: #fff;
    transition: all 0.5s linear;
}

.mobile-checkout {
    position: absolute;
    margin: 20px;
}

.mobile-card-header {
    margin-left: 40px;
}

.bottom-nav {
    .button-row {
        float: right;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.shoe-image {
    height: 64px;
    width: 64px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    z-index: 1;
}

@media print {
    .label-page-break {
        display: block;
        page-break-before: always;
    }
}

.p-detail-flex.view-inventory {
    overflow: hidden;
    .card-body {
        height: 55vh;
    }
}

.listing > .p-detail-tab-wrapper__panel { height: unset !important; }

.p-detail-flex.inventory {
    height: 95%;

    .p-detail-toolbar {
        display: flex;
        justify-content: flex-end;

        .has-next.btn-secondary {
            position: absolute;
            top: 0;
            left: 20px;
        }
    }

    .p-detail-tab-wrapper__panel {
        flex: 1;
        .import-button.disabled {
            pointer-events: none;
            opacity: 0.2;
        }
        .sticky-table.inventory-page.table {
            height: 50vh;

            .btn {
                &:active, &:focus, &:focus-visible, &:hover {
                    border: 0 !important;
                    outline: transparent;
                }
            }
        }
    }
}