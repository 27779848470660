.search-toolbar-container {
  margin-left: 4px;
}

.search-item {
  width: 400px !important;
}

.MuiDataGrid-menuList {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  padding: 8px !important;
}