.table_sort_modal__heading {
  margin: -24px -24px 0 -24px;
  padding: 24px;
  background-color: #ffd0fa;
  text-align: center;

  h3 {
    margin: 0;
    color: #5d0953;
  }
}

.table_sort_modal__heading__items {
  display: flex;
  justify-content: space-between;
  color: #5d0953;
  padding: 10px 0;

  &:not(:last-child) {
    border-bottom: 1px solid #00000010;
  }
}
