.table_tr_options__container {
  background-color: #00000005;
  border-collapse: unset;

  td {
    padding: 0;
  }

  @media (min-width: 992px) {
    display: none;
  }
}

.table_tr_options__wrapper {
  height: 0;
  overflow: hidden;
}

.table_tr_options__content {
  padding: 20px;
  display: grid;
  grid-template-columns: auto auto;
  grid-row-gap: 10px;
}

.table_tr_options__items {
  h4 {
    font-weight: bold;
    margin-bottom: 10px;
    color: #000000;
  }

  span {
    color: #000000;
  }
}
