@import '../../../../assets/scss/copyt-platform-ui/custom/theme';


.select-size {
  margin: 10px 10px 0;
}

#customized-country-button {
  border: 1px solid #0d0d0d;
  box-shadow: none;
  background-color: transparent;
  color: #9a9a9a;
  font-size: 1em;
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-transform: math-auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  span {
    color: black;
  }
}

.country.isBulkEdit {
    padding-left: 10px;
    padding-right: 10px;
  }

.country.hasError {
  border-color: #d3103f !important;
}


.custom-size {
  &-input {
    width: 250px;
    margin: 10px 10px 10px 25px;
  }
  .form-group {
    label {display: none;}
  }
}
.country {
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(90px, 1fr));
  align-items: center;
  border-bottom: 2px solid #eee;
  justify-content: space-around;
  padding: 10px;

  &-section {
    border: 1px solid #000000;
    border-radius: 100px;
    width: 70px;
    margin: 3px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
  }
  &-section.active {
    background: #C4C4C4;
  }
  &-section.disabled {
    pointer-events: none;
    opacity: .3;
  }
  &-body {
    margin: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(80px, 1fr));
    cursor: pointer;

    &-sizes {
      width: 100px;
      border-radius: 3px;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    .active {
      background-color: $theme-muted;
    }
  }
  &-body>* {
    flex: 1 1 80px;
    border: 1px solid;
    text-align: center;
    margin: 5px;

    &:hover, &:active {
      background: #C4C4C4;
    }
  }
  
}