.table_tr {
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid #00000010;
  }

  &:hover {
    background-color: #00000005;
  }

  @media (min-width: 992px) {
    cursor: default !important;
  }
}

.table_td {
  padding: 20px;
  font-weight: normal;
  white-space: unset;
  color: #000000;

  span {
    white-space: nowrap;
  }

  &:not(:last-child) {
    border-right: 1px solid #00000010;
  }
}

.status-btn {
  padding: 5px 20px;
  color: white !important;
  background-color: #d5239e;
  border-radius: 4px;
  border: none;
  font-weight: normal;
}
