.inventory-images {
  .card-body.simplified {
    min-height: 530px;

    .myimages.simplified {
      border: 0;
      box-shadow: none;
      height: unset;
      min-height: 330px;
    } 
  }
  .card-body {
    .myimages {
      overflow-y: auto;
      height: 400px;
      margin-bottom: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: flex-start;
  
      &--card {
        width: 10rem;//calc(50% - 24px);
        height: fit-content;
        margin-left: 16px;
        margin-bottom: 16px;
      }
  
      &--card:nth-child(-n + 3) {
        margin-top: 16px;
      }
    }
  
    .myimages.nophotos {
      align-items: center;
      justify-content: space-around;
      align-content: space-around;
    }
  }
}