@import '../../assets/scss/copyt-platform-ui/custom/theme';

.p-detail-flex.inventory.addItem {
  .p-detail-tab-wrapper__panel {
    height: 100vh !important;
  }
}

body {
  touch-action: pan-x pan-y;
}
.card {
  margin-bottom: 24px;
}

.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.react-select.info {
  color: inherit;
}

.react-select.info.danger {
  .react-select__control { border: 1px solid red;} 
}

.form-control {
  font-size: 14px !important;
}

.img-upload {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 242px;
  border-radius: 6px;
  box-shadow: 0 1px 8px 0 rgba(57, 5, 51, 0.4);
  border: 1px dashed #390533;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.file-count {
  display: inline;
  float: right;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  letter-spacing: 0.075px;
  color: #140010;
  opacity: 0.48;
}

.img-upload-disabled {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 242px;
  border-radius: 6px;
  box-shadow: 0 1px 8px 0 rgba(57, 5, 51, 0.4);
  border: dashed 1px rgba(57, 5, 51, 0.4);
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.upload-btn {
  margin-top: 8px;
}

.react-select .react-select__control .react-select__placeholder {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  padding-right: 8px;
}
.react-select .react-select__control .react-select__value-container {
  width: 75%;
}
.Selection {
  border: solid 3px $theme-primary !important;
  color: $theme-primary !important;
  background-color: rgba(0, 0, 0, 0.12);
}
.img-upload-empty {
  padding: 40px 0px 40px 8px;
}

.img-upload .form-control-file {
  opacity: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  position: absolute;
}
.img-container {
  display: block;
  margin-right: 32px;
  margin-bottom: 14px;
  max-width: 400px;
  height: 260px;
}

.inactive-status {
  width: 72px;
  height: 24px;
  line-height: 24px;
  border-radius: 4px;
  background-color: rgba($theme-secondary, 0.06);
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgba($theme-secondary, 0.72);
}
.active-status {
  width: 72px;
  height: 24px;
  line-height: 24px;
  border-radius: 4px;
  background-color: rgba($theme-secondary, 0.72);
  color: $theme-white;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: $theme-white;
}
.unlisted-status {
  width: 72px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 4px;
  background-color: rgba(19, 166, 148, 0.06);
  color: rgba(19, 166, 148, 0.72);
  float: right;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
}
.listed-status {
  width: 72px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 4px;
  background-color: rgba(19, 166, 148, 0.8);
  color: $theme-white;
  float: right;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
}
.sold-status {
  width: 72px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 4px;
  background-color: #1371a6;
  color: $theme-white;
  float: right;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
}
.processed-status {
  width: 72px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 4px;
  background-color: #5413a6;
  color: $theme-white;
  float: right;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
}
.row-margin {
  margin-left: 16px !important;
}

.custom-checkbox {
  visibility: hidden;
}
.custom-checkbox + span {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
  vertical-align: middle;
  background-image: url('../../assets/img/Icon\ _\ Checkbox.svg');
  background-size: cover;
  cursor: pointer;
}
.custom-checkbox:checked + span {
  background-image: url('../../assets/img/Icon\ _\ Checkbox\ filled.svg');
  background-size: cover;
}

.custom-checkbox + label {
  margin-right: 4px;
}

.img-container .img-input-container {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: calc(100% - 18px);
  border-radius: 4px;
  box-shadow: 0 1px 8px 0 rgba(57, 5, 51, 0.2);
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f5f5f5;
  color: #887f86;
}
.img-container .img-input-container .img-icons {
  margin-left: auto;
  margin-right: auto;
}
.img-container .img-input-container .form-control-file {
  opacity: 0;
  cursor: pointer;
  height: 100%;
  min-width: 100%;
  position: absolute;
}

.img-container.small {
  width: 112px;
  height: 104px;
  flex-wrap: nowrap !important;
}
.card-title {
  width: 100%;
}

.platform-list td {
  border-top: solid 1px !important;
  border-bottom: solid 1px !important;
  color: $theme-black !important;
  padding-left: 0px !important;
}
.table .platform-list-header {
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba($theme-secondary, 0.56);
  text-transform: none;
  padding-bottom: 4px;
  padding-left: 0px;
}
.form-group .form-control[readonly] {
  background-color: transparent;
  cursor: inherit;
  border: 1px solid rgba(20, 0, 16, 0.04);
}

.card .image-cards {
  width: calc(50% - 24px);
  margin-left: 16px;
  margin-bottom: 16px;
  height: fit-content;
}

.card .image-cards:nth-child(-n + 2) {
  margin-top: 16px;
}

.card .my-images {
  height: 544px;
  overflow-y: auto;
  width: 428px;
  display: flex;
  flex-wrap: wrap;
}

.img-x {
  position: absolute;
  top: 8px;
  right: 8px;
  height: 24px;
  width: 24px;
  cursor: pointer;
  background-image: url('../../assets/img/circle-x.svg');
}

.img-x:hover {
  transform: translateY(-1px);
  filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.3));;
}

.empty-img-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.empty-img-svg {
  margin: auto auto 0 auto;
}
.when-upload {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.09px;
  color: #390533;
  margin: 16px 24px auto 24px;
}

.no-photos {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.09px;
  color: #390533;
  margin: 16px 24px 0 24px;
}
.my-images::-webkit-scrollbar {
  background-color: #ffffff;
  width: 6px;
}

.my-images::-webkit-scrollbar-thumb {
  background: rgba(20, 0, 16, 0.24);
  border-radius: 8px;
}
.upload-text {
  display: flex;
  flex-direction: column;
  margin-right: 16px;
}
.drop-here {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: -0.06px;
  color: #212121;
  margin-top: auto;
}

.drop-here-disabled {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: -0.06px;
  margin-top: auto;

  color: #212121;
  opacity: 0.48;
}
.image-supports-disabled {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: -0.05px;
  margin-bottom: auto;

  color: #898989;
  opacity: 0.48;
}
.pink-text {
  display: inline;
  color: #d5239e;
}
.image-supports {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: -0.05px;
  color: #898989;
  margin-bottom: auto;
}
.card .card-header .card-title {
  color: #390533 !important;
  font-size: 18px !important;
  font-weight: 600 !important;
}
.form-group .read-only-label {
  color: rgba($theme-secondary, 0.56);
}

.form-group .error-message {
  display: block;
  color: rgba(174, 10, 0, 1);
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.075px;
  color: #ae0a00;
  mix-blend-mode: normal;
  opacity: 0.8;
  margin: 4px;
}

.form-group .hidden {
  display: none;
}

.modal-open .modal {
  overflow-y: hidden !important;
}
.modal.show .modal-dialog {
  transform: none !important;
}
body .modal-open {
  height: 100vh;
  overflow-y: hidden !important;
}
.soldModal {
  width: 478px !important;
  text-align: center;
}
.soldModal h5 {
  width: 100%;
}

textarea.form-control {
  height: 180px !important;
  max-height: 180px !important;
}
.soldModal .form-group {
  text-align: left;
  width: 322px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.93;
  letter-spacing: normal;
  text-align: left;
  color: rgba($theme-black, 0.56);
}
.soldModal .form-group label {
  margin-bottom: 6px;
}
.soldModal .modal-header {
  padding: 0;
}
.soldModal .modal-header.modal-header-small .modal-title {
  font-size: 18px !important;
}
.soldModal .modal-header .modal-title {
  font-size: 30px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $theme-secondary !important;
  padding: 0;
}
.soldModal .modal-body {
  padding: 8px 0px 16px 0px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.11px;
  text-align: center;
  color: $theme-secondary;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.soldModal .modal-body > div {
  margin-top: 16px;
}

.soldModal .modal-content {
  padding: 24px 32px;
}

.cancelModal .modal-content {
  padding: 0 16px 16px 16px !important;
}

.btn-small {
  width: 112px !important;
}
.btn-small + .btn-small {
  margin-left: 32px !important;
}
.modal-backdrop {
  background: rgba($theme-black, 0.4);
  opacity: 1 !important;
}
.soldModal .modal-body,
.cancelModal .modal-body {
  line-height: normal !important;
}
.cancelModal,
.cancelModal .modal-content {
  width: 400px !important;
  // height:188px!important;
  // padding-top:16px;
  padding-bottom: 16px;
}
.cancelModal .modal-body {
  padding: 0px;
  padding-top: 12px;
  display: block;
  flex-direction: unset;
  align-items: unset;
}
.cancelModal .col-lg-2 {
  padding: 0px !important;
  margin-top: 16px;
}
.cancelModal .col-lg-10 {
  padding: 0px !important;
  margin-top: 20px;
}
.cancelModal .modal-header {
  padding: 0px;
}
.cancelModal .modal-body,
.cancelModal .modal-header .modal-title {
  text-align: left !important;
}

.cancelModal .btn-small {
  margin-top: 20px;
  float: right;
}
.cancelModal .cancel {
  margin-left: 16px !important;
}

ul li {
  color: rgba($theme-black, 1) !important;
}

.add-item.enter-to-continue {
  justify-content: flex-end;
  margin-right: 35px;
}

.image-failure-modal {
  width: auto;
  max-width: 300px !important;

  .listing-failure-header {
    height: auto;
  }

  .listing-failure-details {
    margin: 8px 16px;
  }
}

.image-failure-modal .modal-body {
  padding: 0 !important;
  text-align: center;
}

.image-failure-modal .modal-footer,
.sold-failure-modal .modal-footer {
  padding-top: 16px !important;
  justify-content: flex-end !important;
}
.sku-modal {
  height: 284px;
  width: 478px;
  .modal-content {
    padding: 24px 80px;
  }

  .modal-header {
    padding: 0px;

    .modal-title {
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 36px;
      text-align: center;
      color: #61375c;
      margin: 0px;
    }
  }

  .modal-body {
    padding: 0px;
    margin-top: 8px;
    margin-left: 24px;
    margin-right: 24px;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.10625px;

    color: #61375c;
  }

  .sku-error {
    display: block;
    color: #d3103f;
    text-align: left;
  }
  
  .sku-blk-error {
    display: block;
    color: #000000;
    text-align: left;  
  }

  .sku-hidden {
    display: none;
  }

  .button-row {
    margin-top: 24px;
    display: flex;
    button {
      width: auto;
      flex: 1;
    }
  }

  .form-group {
    margin-top: 16px;
    text-align: left;
  }
}

.sold-modal-footer {
  display: flex;
  flex-direction: row;
  flex: 1 1 0%;
  width: 322px;
  align-items: center;
  justify-content: space-between;
}

.stockx-price {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  height: 100%;
}
.stockx-pricing-title {
  display: flex;
  font-weight: 600;
  align-items: center;
  width: 100%;
}
.price-data {
  width: 100%;
  display: flex;
  align-items: center;
}
.StockX-small-logo {
  background-image: url('../../assets/img/StockX-X.png');
  height: 32px;
  width: 32px;
  background-size: contain;
  margin: 8px;
  margin-left: 0px;
  background-repeat: no-repeat;
}
.flightclub-small-logo {
  background-image: url('../../assets/img/flightclub-logo.png');
  height: 32px;
  width: 32px;
  background-size: contain;
  margin: 8px;
  margin-left: 0px;
  background-repeat: no-repeat;
}

.dbrScanner-video {
  width: 100%;
  border-radius: 6px;
}

.scanner-btn {
  width: 100% !important;
  margin: 8px 0;
}

.barcode-scanner {
  width: 100%;
  display: flex;
  align-items: center;

  .line {
    position: absolute;
    width: 100%;
    background-color: #ae0a00;
    height: 1px;
  }
}

.listing-job {
  width: 100px;
  height: 24px;
  line-height: 24px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
}
.waiting-status {
  background-color: rgba(93, 9, 83, 0.06);
  color: rgba(93, 9, 83, 0.72);
}
.in-progress-status {
  background-color: #5413a6;
  color: #ffffff;
}
.listing-failure-status {
  background-color: #ae0a00;
  color: #ffffff;
}
.listing-success-status {
  background-color: rgba(19, 166, 148, 0.8);
  color: $theme-white;
}
@media (max-width: 641px) {
  .listing-job {
    margin-left: auto;
  }
  .image-failure-modal {
    width: auto;
    max-width: unset !important;
  }
  .card .my-images {
    width: 100%;
    height: fit-content;
  }
  .my-images-label {
    margin-top: 16px !important;
  }
  .mainRow .card .image-cards {
    margin-bottom: 24px;
    box-shadow: 0px 1px 8px rgba(57, 5, 51, 0.2);
    border-radius: 6px;
    border-top: none;
  }
  .card .card-header {
    border-bottom: 1px solid #d5239e !important;
    padding: 0px !important;
    margin: 0 16px !important;
  }
  .col-sm-12,
  .col-md-6,
  .col-lg-6 {
    padding: 0px !important;
  }

  .mainRow {
    width: calc(100% + 30px);
    margin-left: -15px !important;

    .card {
      margin-bottom: 0;
      height: 100% !important;
      box-shadow: none;
      border-top: solid 1px #5d0953;
      border-radius: 0px;
    }
  }

  .btn-secondary,
  .btn-primary,
  .btn-danger {
    width: 128px !important;
  }

  .scanner-btn {
    width: 100% !important;
    margin: 8px 0;
  }

  .button-row-top {
    width: 100%;
    margin-left: 0px !important;
  }

  .flex-nowrap {
    width: 100% !important;
    max-width: 400px;
  }

  .img-upload {
    max-width: 400px !important;
  }

  .img-input-container,
  .img-upload {
    min-width: 0px !important;
  }

  .image-top-box {
    width: 100%;
  }

  .bottom-delete {
    float: none !important;
  }

  .modal-dialog {
    margin: auto 16px !important;
    width: calc(100% - 32px) !important;
  }

  .cancelModal,
  .cancelModal .modal-content {
    width: calc(100% - 16px) !important;
  }

  .soldModal .form-group {
    width: 100%;
  }

  .sold-modal-footer {
    width: 100%;
  }
}

.button-row-top {
  .btn-secondary {
    margin-left: 16px;
  }
}

.button-row-bottom {
  .button-row {
    float: right;
  }
  .btn-secondary {
    margin-left: 16px;
  }
}

.description-group {
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > label {
      font-size: 12px;
      font-weight: 600;
      color: #5d0953;
      margin-bottom: 2px;
    }
    & > label:last-child > span:last-child {
      font-size: 12px;
    }
  }
}

@media (max-width: 480px) {
  .sku-modal {
    .modal-content {
      padding: 24px 16px;
      .modal-header {
        .modal-title {
          text-align: left;
          font-size: 18px;
        }
      }
      .modal-body {
        font-size: 14px;
        text-align: left;
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
}

@media (max-width: 641px) {
  .button-row-top {
    flex-flow: row;
  }
  .buttom-row-bottom {
    flex-flow: row;
  }
}

@media (max-width: 992px) {
  .pricing-card-col {
    padding: '0px 8px 0px';
  }
}

@media (max-width: 440px) {
  .button-row-top .btn-danger,
  .button-row-top .btn-primary,
  .button-row-top .btn-secondary {
    width: 100% !important;
    margin: 4px 0 !important;
  }
}

@media (max-width: 290px) {
  .button-row-top .btn-danger,
  .button-row-top .btn-primary,
  .button-row-top .btn-secondary {
    width: 100% !important;
    margin: 4px 0 !important;
  }

  .modal-dialog {
    width: 100% !important;
    margin: 0px !important;
  }

  .cancelModal,
  .cancelModal .modal-content {
    width: 100% !important;
  }
}

.bottom-nav {
  .button-row {
    float: right;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
