.navbar {
    padding: 0;

    &.navbar-pink {
        background: $theme-primary !important;
      }

  > .container-fluid {
    flex-wrap: nowrap;
    box-shadow: -1px 4px 2px 0 #ffe0f5b3;
  }

  &-wrapper {
    .minimize-sidebar.btn-just-icon > svg {
      color: $theme-white;
    }

    .navbar-brand {
      position: relative;
      color: $theme-white;
      font-size: 18px;
      font-weight: 500;
    }
    .minimize-sidebar {
      .tim-icons.icon-double-right { color: $theme-white; }
    }
  }
  .navbar-collapse {
    .dropdown-toggle {
      color: $theme-white;
    }
  }
  .photo {
    filter: brightness(0) invert(1);
  }
}

.support-textarea .MuiInputBase-multiline {
  padding: 10px;
}