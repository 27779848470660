.p-detail-flex.consignment {
  .p-detail-toolbar.no-print {
    .segments {
      .MuiButtonGroup-root {
        button {
          border: 1px solid #9c27b0;
          background-color: #fff;
          color: #9c27b0;
          padding: 3px 9px;
        }

        button:first-child {
          border-top-left-radius: 5px !important;
          border-bottom-left-radius: 5px !important;
        }

        button:last-child, .Claimable.Items.show {
          border-top-right-radius: 5px !important;
          border-bottom-right-radius: 5px !important;
          border-right-color: #9c27b0 !important;
        }

        .active {
          color: #fff;
          background-color: #d5239e;
        }
      }
    }
  }
}
