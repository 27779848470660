
.copyt-modal__v2 {
  display: flex !important;
  justify-content: center;
  align-items: center;

  .modal-title {
    color: var(--success);
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
  }

  .modal-dialog {
    position: absolute;
    margin: 0 !important;

    .modal-content {
      overflow: hidden;
      background-color: #f0efec;
      border-radius: 10px;

      .modal-body {
        padding: 0;
        overflow: hidden;
        background-color: #f7f7f7;
      }    
    }
  }
}

.copyt-modal__v2.no_padding {
  .modal-body:first-child {
    padding: 0%;
  }
}

.copyt-modal__v2.xxs {
  width: 350px;
}
.copyt-modal__v2.xs {
  width: 460px;
}
.copyt-modal__v2.sm {
  width: 580px;
}
.copyt-modal__v2.md {
  max-width: 700px; width: 65%;
}
.copyt-modal__v2.lg {
  max-width: 820px; width: 80%;
}
.copyt-modal__v2.xl {
  max-width: 980px; width: 92%;
}
.copyt-modal__v2.xxl {
  min-width: 1080px; max-width: 95%;
}

.copyt-modal__v2.xl.address {
  max-width: 1080px;
}

.copyt_modal_container.copyt-modal__v2 {
  .modal-content {
    .modal-title {
      .modal {
        &__heading {
          margin-top: -24px;
          margin-left: -24px;
          margin-right: -24px;
          border-top-left-radius: 0.2857rem;
          border-top-right-radius: 0.2857rem;
          background-color: var(--success);

          h1 {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 25px;
            letter-spacing: 0.09px;
            color: white;
            padding: 20px;
            border-top-left-radius: 0.2857rem;
            border-top-right-radius: 0.2857rem;
          }
      
        }
      }
    }
  }
}
.copyt-modal__v2.no-padding {
.modal-body:first-child {
    padding: 0%;
  }
}

.copyt_modal_container.copyt-modal__v2.no-padding {
  .modal-content {
    .modal-title {
      .modal {
        &__heading {
          background-color: var(--success);
        }
      }
    }
  }
}