.p-detail-flex.empty {
  .p-detail-tab-wrapper__panel {
    padding: 0;
  }
}

.p-detail-tab-wrapper {
  &__panel.hidden-panel {
    visibility: hidden;
    display: none;
  }

  &__panel {
    padding: 20px;

    &--flextool {
      position: sticky;
      display: flex;
      flex-direction: row;
      z-index: 50;
      top: 0;
      padding: 3px 10px;
      width: 100%;
      height: auto;
      background-color: #ececec;
      border-bottom: solid 1px #dddddd;

      .flex-auto { flex: 0 0 auto; }
      .flex-full { flex: 0 1 100%; }

      .btn,
      .btn:active,
      .btn:focus,
      .btn:hover {
        border-color: #cccccc;
        background-color: #ffffff;
        box-shadow: none;
        color: #555555;

        i.fa {
          margin-right: 5px;
        }
      }

      .btn-tool {
        padding-left: 22px;
        background-repeat: no-repeat;
        background-position: 5px center;
        background-size: 14px;
      }
      .btn-expand {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAABXUlEQVRoge2ZwW7CMAyGf+0dVsH7P8lWaQi2EzvwOHBgnUKWOLbruJnwJ1Vc4tZfg5rYAYIgeGquwmte8axZ8bw/vKxIIKV48xFI7S8A9hvm8grgjMaM1FgCvpPfyThBDlOWg1okfRveM5M/e4cVIqUbesiUJPK8WOQBnjI1iVJeTUoBHjKURC0vklpAT5mWBJVXFSqghwxHopWXKsBShivByUsVYCEjkeDm9cAM4J0xLl+wJIumJpablwrNzEhnwg2JzLASC+lf5USM+8S2+zcWE4AvAB/EmMPPmGElgiAI/h8T7p/eN2LMjME/v+mCeCTGnTDwgijZdmzZ0CDR7J3cZI64byVaeG/juXn9oimsNLtY6cx0L3XXbMUlMl2bDxb1BFemWzvIsijiyHRp0PWo7Foy5i3TnuUpJWPaxPaosWsyZscKno2Ckkwc9IzQsjE5euNecaobBEEg4gZzdCtYDNEjQQAAAABJRU5ErkJggg==');
      }
      .btn-collapse {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAABcUlEQVRoge2aS07DMBBAn7gDEdz/JBCJqqXdwILjhEUbKTKeZjwef6j8pKxSe+Z1ItnjBAaDQSrvwBmYWicSYQIuwJvmxydgAb7pS2bimtMCHDUDnoGv24Af4LVYanrCnF6sA1vKmCVWtqVMecyOwCHjvkcOf7BUZrld1vtS7ORK7E24J+Mh4i4hTXxPJlekmIQUQJLJESkuIQWKyVhFqklIAUMZi0h1CSnwViZVpJmElMAqkyLSXGIlJqMV6UZiJVx9tSIuK7Y3239XK1K9EnMQXHtJWOaa95J8StZqg2ZvNhgMBg/GWEccqbaO5GxRejg3Ax5k0xjbilu38c0qI/UTOY1VdZl7TVFuq1tNZq+z8zh8KC6jaU8tIrG5i8loe2yrSCyGu0zKQUGOSCyWm0zqaUeuSCxmtozlyMZDJBbbLGN9yTJzfaFqve+Rg98Ejphz6e4EEONj9kkflQjZVuakGXCg7w8GzsBH60QGg//GL0kfQp/bBrBOAAAAAElFTkSuQmCC');
      }
    }
  }
}