@import '../../assets/scss/copyt-platform-ui/custom/theme';

@font-face {
  font-family: 'Azonix';
  src: url(../../assets/fonts/Azonix.ttf) format('truetype'),
    url(../../assets/fonts/Azonix.svg) format('svg'), url(../../assets/fonts/Azonix.woff) format('woff'),
    url(../../assets/fonts/Azonix.eot) format('embedded-opentype');
}

.premium-modal.standard {
  .modal-content {
    height: 80%;
  }
}

.premium-modal {
  height: calc(100% - 3rem);
  .modal-body {
    padding: 0;
  }
  .modal-content {
    background-image: url('../../assets/img/Shoes.png'),
      linear-gradient(180deg, rgba(213, 35, 158, 0.12) 0%, rgba(255, 255, 255, 0.8) 100%);
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100% 100%;
    border-radius: 6px;
    background-color: #d5239e;
    height: 100%;
    box-shadow: none;
    padding: 24px;
    .premium-close {
      width: 45px;
      border: none;
      background: none;
    }

    .white-bubble {
      background-color: #fffbff;
      border-radius: 24px;
      box-shadow: 0px 1px 8px 0px #1400103d;
      margin-top: 130px;
      padding: 12px 16px;
    }
    .modal-gradient {
      background: linear-gradient(180deg, rgba(255, 208, 250, 0) 0%, rgba(93, 9, 83, 0.48) 100%);
      height: 100%;
      width: 100%;
    }

    .modal-header {
      padding: 0;
    }

    .modal-footer {
      position: absolute;
      padding: 0 !important;
      justify-content: center;
      bottom: 0;
      width: 100%;
    }

    .premium-modal-title {
      font-family: 'Azonix';
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      line-height: 35px;
      text-align: center;
      color: #d5239e;
      text-shadow: -4px -3px #ffd0fa;
    }

    .premium-benefits {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #140010;
      display: flex;
      flex-direction: column;

      .premium-benefits-item {
        display: flex;
        flex-direction: row;
        padding-top: 10px;
        padding-left: 15px;
      }

      .premium-benefits-description {
        color: #140010;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 16px;
        text-align: center;
        margin-top: 8px;
        margin-left: 16px;
        margin-right: 16px;
        .premium-benefits-description-link {
          color: #d5239e;
        }
      }

      .premium-benefits-text {
        color: #140010;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 15px;
        text-align: left;
      }

      .premium-icon {
        margin-right: 12px;
        height: 48px;
        width: 48px;
        min-height: 48px;
        min-width: 48px;
        margin-top: 5px;
      }
    }

    .premium-advert {
      border: 2px solid #140010;
      border-radius: 50%;
      height: 198px;
      width: 198px;
      text-align: center;
      overflow: hidden;
      vertical-align: middle;
      display: table-cell;
      background-color: #fffbff;
      font-weight: 700;
      font-size: 18px;
    }

    .premium-price {
      color: #d5239e;
    }

    .premium-button {
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      height: 40px;
      text-transform: uppercase;
      border-radius: 6px;
      background-color: #d5239e;
      color: #ffffff;
      border: none;
      box-sizing: border-box;
      cursor: pointer;
      width: 100%;
      margin-top: 16px;
      box-shadow: 1px 2px #8d7a7aee;
      border: 1px solid;
    }

    .premium-button.skip-paywall {
      width: 20%;
      margin: 16px 10px 0;
      background: #ff0000;
      box-shadow: 1px 2px #8d7a7aee;
      border: 1px solid;
    }

    .paywall-footer {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      flex-wrap: nowrap;
      align-items: center;
    }
    .skip {
      margin-top: 16px;
    }
  }
}
