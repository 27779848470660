.hide {
  display: none !important;
}

.platforms {
  .p-detail-content {
    margin-bottom: 0 !important;
    svg.icon {
      position: absolute;
      top: 0;
      left: 0;
      height: 90px !important;
    }

    svg.upgrade {
      @extend .icon;
    }

    svg.ecg {
      @extend .icon;
    }
  }
}
.platform-card-big {
  .platform-icon {
    height: 40px;
    width: 40px;
    min-height: 40px;
    min-width: 40px;
    margin-right: 16px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .platform-card-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
    min-height: 65px;
    max-height: fit-content;
  }

  .active-listings {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.1px;
    color: #2b991a;

    margin-bottom: 8px;
  }

  .all-time {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.1px;
    color: #5d0953;
  }

  .failed-listings {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.1px;
    color: #ae0a00;
  }

  .progress {
    height: 16px;
    border-radius: 6px;
    box-shadow: none;
    background-color: rgba(82, 55, 74, 0.16);
    .bg-primary {
      background-color: rgba(213, 35, 158, 0.32) !important;
    }
  }
}

.platform-card {
  cursor: pointer;
  &:hover {
    background-color: none;
    opacity: 1;
  }
}

.shop-platform-card {
  cursor: pointer;
  &:hover {
    background-color: #ffd0fa;
    opacity: 0.76;
  }
}

.input-note {
  margin-top: -8px;
  margin-bottom: 16px;
}

.search-bar {
  margin: 4px 12px 4px 0px;
}

.checkbox-text {
  text-transform: capitalize;
}
