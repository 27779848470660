@import '../../../../assets/scss/copyt-platform-ui/custom/theme';

.shopify-billing-container {
  margin: 2% 10% 0;

  .shopify-continue-btn {
    color: #5d0a53;
    opacity: 1;
  }

  .grid {
    &-container { margin-top: 5%; }
    &-container, &-item {
      display: flex;
      justify-content: space-around;

      .shopify {
        font-weight: 700;
        font-size: 16px;
        color: #390533;
      }

      .price {
        font-weight: 700;
        font-size: 35px;
        color: #390533;

        span {
          font-size: 14px;
        }
      }

      .ready-to {
        font-weight: 600;
        text-align: center;
        color: $theme-white;
      }

      .feature {
        display: flex;
        flex-direction: column;
        margin: 10px 2% 0 5%;

        p {
          color: #140010;
          text-align: left;
        }

        .title {
          font-size: 14px;
          margin-bottom: 10px;
        }

        .subtitle {
          font-size: 16px;
        }

        &-item {
          display: flex;
          align-items: flex-start;
          text-align: left;
          justify-content: flex-start;
          gap: 10px;
          
          .MuiSvgIcon-root {
            path {
              color: #d5239e;
            }
          }
        }
      }
    }

    &-item {
      display: flex;
      justify-content: space-around;
    }
  }

  button {
    width: 90%;
  }
}