$theme-primary: var(--primary) !default;
$theme-secondary: var(--success) !default;
$theme-muted: #ffd0fa !default;
$theme-background: #fffbff !default;
$theme-text-primary: rgba(#140010, 0.8) !default;
$theme-black: #140010 !default;
$theme-success: #2b991a !default;
$theme-danger: #d3103f !default;
$theme-white: white !default;
$theme-box-shadow: 0 1px 8px 0 rgba($theme-secondary, 0.24);
$theme-button-secondary: #9c27b0 !default;
$theme-default: #344675 !default;
