.sidebar {
	transform: none !important;
	z-index: 1052 !important;
	height: calc(100vh - 90px) !important;
	border-radius: 5px !important;
	margin-top: 76px !important;
    margin-left: 20px !important;
	.logo {
		background-image: unset;
		height: unset;
		width: unset;
		margin-top: 0;
	}
}