.support-modal {
	max-width: 350px !important;
	margin-left: auto;
	margin-right: auto;
}

.support-primary-button {
	flex: 1;
	margin-left: 8px !important;
}

.support-secondary-button {
	flex: 1;
	margin-right: 8px !important;
}

.navbar-nav {
	.account-circle {
		height: 30px;
		width: 30px;
	}
}