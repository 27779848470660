.copyt_modal_container.duplicate-modal {
  .modal__heading h1 {
    text-align: left;
    margin-bottom: 20px;
  }
  
  .modal-content:first-child {
    min-height: 300px;
    max-height: 550px;

    .modal-body:first-child {
      max-height: unset;
      height: unset;
      overflow-y: unset;

      .modal-body.form-item {
        min-height: 100px;
        height: unset;
        max-height: 300px;
        overflow-y: auto;
        padding: 0 10px;
        margin: 10px 0;
      }
    }

    .modal-footer {
      padding: 0;
      bottom: 0;
    }
  }
}

.duplicate-modal {
  .modal-content {
    .modal-body {
      max-height: 400px;
      height: 400px;
      overflow-y: auto;

      span {
        font-size: 0.9em;
        color: red;
        display: flex;
        justify-content: center;
      }

      .duplicate-item {
        &-row {
          display: flex;
          align-items: center;
        }

        &-column {
          margin: 0 5px;

          .form-group {
            .customized-country {
              width: 135px;
            }
          }
        }

        &-action {
          padding-top: 12px;

          &:hover,
          &:focus {
            cursor: pointer;
          }
        }
      }
    }
  }

}

.duplicate-header {
  .modal-title {
    display: flex;
    flex-direction: column;

    >span {
      text-align: left;
      font-size: 13px;
      margin-top: 20px;
      font-weight: 500;
    }
  }
}
