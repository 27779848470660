@import '../../../../assets/scss/copyt-platform-ui/custom/theme';

.p-detail-flex.inventory.view-inventory {
  .p-detail-toolbar.no-print.v2.MuiBox-root {
    justify-content: space-between !important;
    .MuiButtonBase-root.import {
      background-color: $theme-secondary;
    }
  }
  .empty-state-card {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
  }
  .list-all-btn { margin: 0; color: $theme-primary; }
  .MuiButtonBase-root {
    padding: 12px 16px;
  }
  .MuiBox-root.inventory-container.unlisted-table {
    .MuiDataGrid {
      &-root {
        .MuiButtonBase-root {
          font-weight: 700;
          color: $theme-primary;
        }
      }
    }

    .MuiButtonBase-root.Mui-disabled.MuiDataGrid-checkboxInput.MuiCheckbox-root {
      visibility: hidden;
    }
  }

  .MuiBox-root.inventory-container {
    [data-field="title"], [data-field="owner"] {
      span, div.MuiDataGrid-cellContent {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .MuiBox-root.inventory-container.listed-table {
    [data-field="assets"] {
      padding: 0;
      svg {
        z-index: 2;
        // width: 50px;
        height: fit-content;
      }
    }
  }

  .MuiBox-root.inventory-container {
    height: 100%;
    box-shadow: 0px 0px 10px #a7a9a880;

    .MuiDataGrid-main {
      > div:first-child {
        z-index: 1;
        pointer-events: auto !important;
      }
    }

    .MuiDataGrid {
      &-root {
        &--densityStandard {
          border-radius: 0;
        }
        .MuiCheckbox-root {
          font-weight: 700;
          color: $theme-primary;
        }
        .MuiDataGrid {
          &-renderingZone, &-row {
            max-height: none !important;
            cursor: pointer;
          }
          &-cell {
            line-height: unset !important;
            max-height: none !important;
            white-space: normal !important;
          }
          &-columnHeadersInner {
            // align-items: baseline;
          }
          &-columnHeaderTitle {
            text-overflow: clip;
            white-space: break-spaces;
            line-height: 1;
            font-weight: 500;
            // font-size: 0.8em;
            // text-align: center;
            // align-items: baseline;
          }
        }
      }
    }
  }

  .img-overlay-wrap.no-image {
    height: 50px;
  }

  .img-overlay-wrap {
    position: relative;
    display: inherit;//inline-block;
  }

  .img-overlay-wrap img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  .img-overlay-wrap svg {
    position: absolute;
    top: 0;
    left: 0;
    height: 90px !important;
  }
}
