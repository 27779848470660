.p-wrapper.collapsed {
	.master-table .table-head .p-table-head {
		flex-direction: unset;
		align-items: unset;
		justify-content: unset;

		.logo .simple-text.logo-normal > div { display: none;}
		.logo-img { justify-content: unset; }
	}
}

.master-table {
	-webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;

		.notification-badge {
			height: 16px;
			width: 16px;
			background-color: #f00;
			border-radius: 50%;
			display: inline-block;
			position: absolute;
			color: #fff;
			font-size: 0.6em;
			padding: 1.6px;
			font-family: sans-serif;
			top: -7px;	
		}

		.notification-badge.more {
			font-size: 0.4em;
			padding: 4px 0;
		}

	.table-head {
		-webkit-box-flex: 0;
		-ms-flex: 0 1 auto;
		flex: 0 1 auto;

		.p-table-head {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-around;
			flex-wrap: nowrap;
			.logo {
				background-image: unset;
				height: unset;
				width: unset;
				margin-top: 0;
		
				&-img {
					display: flex;
					justify-content: center;
				}
			}
		}
	}

	.table-body {
		-webkit-box-flex: 1;
		-ms-flex: 1 1 100px;
		flex: 1 1 100px;
		overflow-x: hidden;
		overflow-y: auto;
		scroll-behavior: smooth;
		border-top: 1px solid var(--lighter);
		background-color: white;

		.nav {
			display: flex;
			flex-direction: column;
		}

		.nav > li {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			align-items: center;
			height: 50px;
			border-bottom: 1px solid var(--lighter);

			&:hover {
				background-color: var(--primary-light);
				cursor: pointer;
			}
		}

		.nav > li.active { background-color:  var(--primary-light); }

		.nav > li > a {
			display: flex;
    		align-items: center;
			padding: 10px 13px;
			width: 100%;

			.tim-icons {
				font-size: 25px;
				margin-right: 17px;
				width: 34px;
				text-align: center;
				color: var(--sucess);
				position: relative;
				font-weight: 700;
			}

			.tim-icons.business-network {
				background-image: url('../../../assets/img/Business_Network.png') !important;
				background-size: contain;
				height: 28px;
				width: 28px;
				margin-left: 5px;
				background-repeat: no-repeat;
			}

			.tim-icons.inventory-icon {
				background-image: url('../../../assets/img/Inventory-Icon.png') !important;
				background-size: contain;
				height: 28px;
				width: 28px;
				margin-left: 5px;
				background-repeat: no-repeat;
			}

			> p {
				margin: 0;
				color: var(--sucess);
				font-weight: 500;
			}

		}

		.MuiAccordionSummary-root {
			padding: 0;
			width: 100%;
			border-bottom: 1px solid var(--lighter);
  	}
		.parent-active > .MuiAccordionSummary-root {
			background-color: var(--lighter);
      border-left: 5px solid var(--gray);
		}
	}
}