
.btn-flex-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  .btn-primary.ripple {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    height: 40px;
    width: 160px;
    text-transform: uppercase;
    border-radius: 6px;
    background-color: #d5239e;
    color: #ffffff;
    box-shadow: 0 1px 8px 0 #666;
    border: none;
    box-sizing: border-box;
    outline: none !important;
  }

  .btn-secondary.white-ripple {
    font-weight: 600;
    border-radius: 6px;
    color: #d5239e;
    box-shadow: 0 1px 8px 0 #666;
    border: 1px solid #d5239e;
  }

  .btn-loading {
    color: #d5239e;
    margin-right: 30px !important;

    &:focus, &:active, &:hover {
      outline: 0;
      background: transparent;
      cursor: default;
    }
  }
}

.border-solid-white {
  border: 1px solid #ffffff !important;
}


