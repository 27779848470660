.delete-all-modal {
  .modal-content {
    .modal-title {
      .modal {
        &__heading h1 {
          text-align: left;
          }
        }
      }
      .modal-body {
        .modal-body {
          padding: 0;
          .delete-section {
            display: flex;
            justify-content: space-around;
            svg {
              font-size: 90px;
            }
            span {
              display: flex;
              flex-direction: column;
              justify-content: center;
            }
          }

          .delete-btn {
            display: flex;
            justify-content: space-evenly;
            margin: 30px auto 20px;
          }
        }
      }
    }
}