@import '../../../../assets/scss/copyt-platform-ui/custom/theme';

.MuiDataGrid-filterForm {
  .MuiFormLabel-root.Mui-focused {
    color: $theme-primary;
  }
  .css-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root:after, 
  .css-1ptx2yq-MuiInputBase-root-MuiInput-root:after {
    border-bottom: 2px solid $theme-primary;
  }
}

.filter-list {
  align-items: center;
  margin: 5px 0 7px !important;
  button.filter-clear {
    background-color: transparent;
    border: 0;
    padding: 0;

    svg {
      height: 15px;
    }
  }
  .txtfield-value > label {
    margin-top: -9px;
    font-size: 0.9em;
    color: #000;
  }

  .txtfield-value {
    input.MuiInputBase-input { padding: 6px 7px 7px; }
  }

  .toolbar-filter-by {
    .MuiInputBase-input.MuiOutlinedInput-input {
      padding-left: 10px;
      font-size: 0.8em;
    }
  }

  .toolbar-filter-by.category, .toolbar-filter-by.listingPlatformStatus, .toolbar-filter-by.platform, .toolbar-filter-by.soldPlatform {
    .MuiInputBase-input.MuiOutlinedInput-input {
      min-width: 138px;
    }
  }

    .toolbar-filter-by.consignmentStatus {
    .MuiInputBase-input.MuiOutlinedInput-input {
      min-width: 108px;
    }
  }

  .apply-filter-btn {
    display: flex;
    width: 100%;
    justify-content: space-evenly;

    button {
      height: 30px;
      width: 145px;
    }
  }
}
