// Core

@import 'copyt-platform-ui/custom/functions';
@import 'copyt-platform-ui/bootstrap/functions';

@import 'copyt-platform-ui/custom/variables';
@import 'copyt-platform-ui/bootstrap/variables';
@import 'copyt-platform-ui/custom/theme';

@import 'copyt-platform-ui/custom/mixins';
@import 'copyt-platform-ui/bootstrap/mixins';
// Bootstrap components

@import 'copyt-platform-ui/bootstrap/root';
@import 'copyt-platform-ui/bootstrap/reboot';
@import 'copyt-platform-ui/bootstrap/type';
@import 'copyt-platform-ui/bootstrap/images';
@import 'copyt-platform-ui/bootstrap/code';
@import 'copyt-platform-ui/bootstrap/grid';
@import 'copyt-platform-ui/bootstrap/tables';
@import 'copyt-platform-ui/bootstrap/forms';
@import 'copyt-platform-ui/bootstrap/transitions';
@import 'copyt-platform-ui/bootstrap/dropdown';
@import 'copyt-platform-ui/bootstrap/button-group';
@import 'copyt-platform-ui/bootstrap/toasts';
@import 'copyt-platform-ui/bootstrap/custom-forms';
@import 'copyt-platform-ui/bootstrap/nav';
@import 'copyt-platform-ui/bootstrap/navbar';
@import 'copyt-platform-ui/bootstrap/pagination';
@import 'copyt-platform-ui/bootstrap/badge';
@import 'copyt-platform-ui/bootstrap/jumbotron';
@import 'copyt-platform-ui/bootstrap/alert';
@import 'copyt-platform-ui/bootstrap/progress';
@import 'copyt-platform-ui/bootstrap/media';
@import 'copyt-platform-ui/bootstrap/list-group';
@import 'copyt-platform-ui/bootstrap/close';
@import 'copyt-platform-ui/bootstrap/modal';
@import 'copyt-platform-ui/bootstrap/tooltip';
@import 'copyt-platform-ui/bootstrap/popover';
@import 'copyt-platform-ui/bootstrap/carousel';
@import 'copyt-platform-ui/bootstrap/utilities';
@import 'copyt-platform-ui/bootstrap/print';

// Custom components

@import 'copyt-platform-ui/custom/reboot.scss';
@import 'copyt-platform-ui/custom/breadcrumb';
@import 'copyt-platform-ui/custom/global.scss';
@import 'copyt-platform-ui/custom/utilities.scss';
@import 'copyt-platform-ui/custom/alerts.scss';
@import 'copyt-platform-ui/custom/avatars.scss';
@import 'copyt-platform-ui/custom/badge.scss';
@import 'copyt-platform-ui/custom/buttons.scss';
@import 'copyt-platform-ui/custom/close.scss';
@import 'copyt-platform-ui/custom/custom-forms.scss';
@import 'copyt-platform-ui/custom/dropdown.scss';
@import 'copyt-platform-ui/custom/footer.scss';
@import 'copyt-platform-ui/custom/forms.scss';
@import 'copyt-platform-ui/custom/grid.scss';
@import 'copyt-platform-ui/custom/icons.scss';
@import 'copyt-platform-ui/custom/images.scss';
@import 'copyt-platform-ui/custom/input-group.scss';
@import 'copyt-platform-ui/custom/info-areas.scss';
@import 'copyt-platform-ui/custom/modal.scss';
@import 'copyt-platform-ui/custom/nav.scss';
@import 'copyt-platform-ui/custom/navbar.scss';
@import 'copyt-platform-ui/custom/pagination.scss';
@import 'copyt-platform-ui/custom/tabs.scss';
@import 'copyt-platform-ui/custom/pills.scss';
@import 'copyt-platform-ui/custom/popover.scss';
@import 'copyt-platform-ui/custom/progress.scss';
@import 'copyt-platform-ui/custom/type.scss';
@import 'copyt-platform-ui/custom/tables';
@import 'copyt-platform-ui/custom/checkboxes-radio';
@import 'copyt-platform-ui/custom/fixed-plugin';
@import 'copyt-platform-ui/custom/sidebar-and-main-panel.scss';
@import 'copyt-platform-ui/custom/misc.scss';
@import 'copyt-platform-ui/custom/rtl.scss';
@import 'copyt-platform-ui/custom/timeline.scss';
@import 'copyt-platform-ui/custom/table-header.scss';
@import 'copyt-platform-ui/custom/table-head.scss';
@import 'copyt-platform-ui/custom/table-row.scss';
@import 'copyt-platform-ui/custom/table-row-details.scss';
@import 'copyt-platform-ui/custom/table-sort-modal.scss';
@import 'copyt-platform-ui/custom/dropdown-menu-listing.scss';
@import 'copyt-platform-ui/custom/input-search.scss';
@import 'copyt-platform-ui/custom/skeleton.scss';
@import 'copyt-platform-ui/custom/skeleton_table.scss';

// Vendor / Plugins

@import 'copyt-platform-ui/custom/vendor/plugin-datetimepicker.scss';
@import 'copyt-platform-ui/custom/vendor/plugin-nouislider.scss';
@import 'copyt-platform-ui/custom/vendor/plugin-fullcalendar.scss';
@import 'copyt-platform-ui/custom/vendor/plugin-sweetalert2.scss';
@import 'copyt-platform-ui/custom/vendor/plugin-bootstrap-switch.scss';
@import 'copyt-platform-ui/custom/vendor/plugin-bootstrap-select.scss';
@import 'copyt-platform-ui/custom/vendor/plugin-jasny-fileupload.scss';
@import 'copyt-platform-ui/custom/vendor/plugin-tagsinput.scss';
@import 'copyt-platform-ui/custom/vendor/plugin-perfect-scrollbar.scss';
@import 'copyt-platform-ui/custom/vendor/plugin-jquery.jvectormap.scss';
@import 'copyt-platform-ui/custom/vendor/plugin-datatables.net.scss';
@import 'copyt-platform-ui/custom/vendor/plugin-animate-bootstrap-notify.scss';
@import 'copyt-platform-ui/custom/vendor/plugin-card-wizard.scss';
@import 'copyt-platform-ui/custom/vendor/plugin-chartjs.scss';

// example pages and sections

@import 'copyt-platform-ui/custom/example-pages.scss';

// light mode

@import 'copyt-platform-ui/custom/white-content.scss';

// Cards

@import 'copyt-platform-ui/custom/card';

// React Differences
@import 'copyt-platform-ui/react/react-differences';

[placeholder] {
  text-overflow: ellipsis;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  text-overflow: ellipsis;
}
::-moz-placeholder {
  /* Firefox 19+ */
  text-overflow: ellipsis;
}
:-ms-input-placeholder {
  /* IE 10+ */
  text-overflow: ellipsis;
}
:-moz-placeholder {
  /* Firefox 18- */
  text-overflow: ellipsis;
}
