@import '../../assets/scss/copyt-platform-ui/custom/theme';

.flex-wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
  flex-flow: column;
  height: 100%;
}
/* Custom Wrapper */
.p-wrapper {
  position: absolute;
  top: 52px; left: 0; right: 0; bottom: 0;
  background: #f1f1f1;
  visibility: visible;
}
.p-panel-fullview {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
  flex-flow: column;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
.p-panel-fullview .p-panel-header { flex: 0 1 auto; }
.p-panel-fullview .p-panel-body { flex: 1 1 100%; }

.p-panel-master {
  position: absolute;
  top: 0; left: 0; bottom: 0;
  padding: 0 0 0 0;
  width: 250px;

  .sidebar {
    border-radius: unset !important;
    margin: 0 !important;
    height: 100% !important;
    width: 250px;
    transition: 0.5s;
  }
}

.p-panel-master .p-table-head [class*="col-"] { padding: 0 7px; }
.p-panel-detail {
  position: absolute;
  top: 0; left: 250px; right: 0; bottom: 0;
  // overflow-x: hidden;
  overflow: hidden;
  background: #f8f8f9;
  border-left: solid 1px #dddddd;
  height: 100%;

  .MuiButtonGroup-grouped {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right-color: transparent !important;
  }
}
.p-detail-flex, .copyt_modal_container {
  height: 95%;

  .MuiDataGrid-columnHeaders {
    background: #ffbeeb36;
    color: $theme-primary;

    .MuiDataGrid-columnHeaderTitle {
      font-weight: 700;
    }
  }

  .MuiInputBase-root.Mui-focused {
    color: $theme-primary;
    &::after {
      border-bottom: 2px solid $theme-primary;
    }
  }
}

.p-detail-flex .p-detail-toolbar {
  flex: 0 1 auto;
  padding: 5px 20px;
  border-bottom: solid 1px var(--gray-light);
  background-color: var(--lighter);

  &__panel {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.p-detail-flex .p-detail-content {
  flex: 1 1 100%;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
  margin-bottom: 30px;
}

.p-detail-expand {
  display: block;
  width: 30px;
  height: 100%;
  border-radius: 3px;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 18px;
  color: transparent;
  outline: none;
}

.p-detail-flex .p-detail-tab-wrapper {
  position: relative;
  flex: 1 1 100%;
  overflow: hidden;
  scroll-behavior: smooth;

  &__panel {
    &--toolbar {
      position: sticky;
      z-index: 50;
      top: 0;
      padding: 3px 0;
      width: 100%;
      height: auto;
      background-color: #ececec;
      border-bottom: solid 1px #dddddd;
    }
  }

  &__panel.scrollable { overflow-y: scroll; }
  &__panel.hidden-panel { visibility: hidden; }
}

.p-detail-flex.full-height  {
  .p-detail-tab-wrapper__panel { height: 100%; }
}

/* Override Wrapper */
.p-wrapper.hidden { visibility: hidden; }
.p-wrapper.expanded .p-panel-master { visibility: hidden; }
.p-wrapper.expanded .p-panel-detail { left: 0; }
.p-wrapper.collapsed {
  .p-panel-master { width: 65px; }
  .p-panel-detail { left: 66px; }
}
.p-wrapper.collapsed.isMobile { .p-panel-detail { left: 0; } }

.master-table {
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
  flex-flow: column;
}

.master-table .table-head { 
  flex: 0 1 auto;
}

.master-table .table-body {
  flex: 1 1 100px;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
  border-top: solid 1px #dddddd;
  background-color: #fefefe;
}

/* Page Content View */
.content-view {
  padding-left: 30px;
  padding-right: 30px;
}
.content-view .container-fluid .row {
  margin-left: 0;
  margin-right: 0;
}
.content-summary {
  padding: 0;
}

.full-width { width: 100% !important; }
.quarter-more-width { width: 75%; }
.half-width { width: 50%; }
.quarter-width { width: 25%; }
.display-flex {
  display: flex;
  align-items: center;
}

.enter-to-continue {
  display: flex;
  justify-content: space-around;
  margin-top: 5px;
}

.full-height { height: 100% !important;}

.no-scroll { overflow: hidden !important; }

.MuiDataGrid-columnHeaders, .MuiDataGrid-footer {
  background: var(--primary-light);
  color: $theme-primary;
  .MuiDataGrid-columnHeaderTitle {
    font-weight: 600;
  }
}
.MuiDataGrid-footerContainer {
  background-color: var(--secondary);
  .MuiTablePagination-displayedRows {
    color: $theme-primary;
  }
}
