@import '../../../../assets/scss/copyt-platform-ui/custom/theme';

.p-detail-flex {
  display: flex;
  flex-flow: column;

  .p-breadcrumb {
    flex: 0 1 auto;
    padding: 5px 20px;
    border-bottom: solid 1px #dddddd;
    background-color: $theme-white;
    display: flex;
    justify-content: space-between;

    .breadcrumb {
      margin: 0;
      padding: 0;

      &-item {
        font-size: 1em;
      }

      &-item.active > a {
        color: $theme-primary;
      }
    }
    .yt-links {
      color: blue;
      cursor: pointer;
    }
  }
}

.yt-links-container {
  max-width: 800px;
  margin: auto;
  width: 50%;
  height: 80%;
}

.yt-title {
  padding-bottom: 8px;
}

.yt-section {
  padding-left: 0px !important;
}

.yt-links-wrapper {
  background-color: white;
  border-bottom-right-radius: 0.2857rem;
  border-bottom-left-radius: 0.2857rem;
}

.yt-links-header {
  background-color: #e14eca;
  color: white;
  text-align: center;
  padding: 20px 0 20px 0;
  border-top-right-radius: 0.2857rem;
  border-top-left-radius: 0.2857rem;
  align-items: center;
}

.yt-video {
  margin-bottom: 16px;
  outline: 1px solid;
}
