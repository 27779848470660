.copyt_modal_container.sku-upc-modal {
  .modal-content {
    color: #2f052a;
  }

  .react-select.search-by .react-select__control .react-select__value-container {
    max-width: 60px;
  }

  .react-select.search-value .react-select__control .react-select__value-container {
    max-width: 200px;
  }

  .react-select.search-value .react-select__control .react-select__value-container {
    width: 85%;
  }
}

