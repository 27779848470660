.footer{
  padding: 0 30px;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #fefefe;
  height: 5%;
  display: flex;
  align-items: center;

    [class*="container-"] {
      padding: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .nav {
        display: inline-block;
        float: left;
        margin-bottom: 0;
        padding: 0;
        list-style: none;
    }

    .nav-item{
      display: inline-block;

      &:first-child a {
        padding-left: 0;
      }
    }

    .nav-link {
      color: $white;
      padding: $padding-base-vertical;
      font-size: $font-size-sm;
      text-transform: uppercase;
      text-decoration: none;

      &:hover{
          text-decoration: none;
      }
    }



    .copyright{
        font-size: $font-size-sm;
        line-height: 1.8;
        color: $primary;
    }

    &:after{
        display: table;
        clear: both;
        content: " ";
    }
}
