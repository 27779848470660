.error-message {
    font-size:18px;
    margin: 24px
}

.error-title {
    font-size: 24px;
    font-weight: 600px;
    margin-top:12px;
}
.error {
    background-color: #140010;
    height:100vh;
    width:100vw;
    opacity: 0.9;
    transition: opacity 0.3s ease-in 0s;
    transition-property: opacity;
    transition-duration: 0.3s;
    transition-timing-function: ease-in;
    transition-delay: 0s;
}

.error-container {
    margin:auto;
    width:300px;
    height:100%;
    display:flex;
    
}

.error-card {
    width: 300px;
    height:415px;
    /* padding:24px; */
    margin:auto!important;
    text-align: center;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    color: #333;
    font-family: "Avenir Next", Avenir, -apple-system, BlinkMacSystemFont, Roboto, Helvetica, sans-serif;
    text-rendering: optimizeLegibility;
    transition: transform 0.6s, opacity 0.6s;
    transition-delay: 0.5s;
    box-shadow: 0 0 40px 4px #111118;

}

.return-login {
    height:70px;
    width:300px;
    margin: auto auto 0px auto;
    background-color: #f240dd;
    border-radius: 0 0 5px 5px;
    border: none;
    color: #fff;
    outline: none;
    letter-spacing: 1px;
    font-size: 14px;
    cursor: pointer;
}

.logo {
    /* background-image: //url("/assets/img/copyt-logo-sidebar.svg"); */
    background-image: url("../../assets/img/copyt-logo.svg");
    background-repeat: no-repeat;
    background-size: contain;
    margin-left:auto;
    margin-right:auto;
    width: 58px;
    height: 58px;
    display: inline-block;
    margin: 16px 0 11px;
    vertical-align: middle;
}

.top-section {
    background-color: rgba(241,241,241,0.8);
    font-size: 22px;
    padding: 10px 10px;
    line-height: 30px;
    border-radius: 5px 5px 0 0 ;
    

}