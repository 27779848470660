/* ========================================================================
 * bootstrap-switch - v3.3.4
 * http://www.bootstrap-switch.org
 * ========================================================================
 * Copyright 2012-2013 Mattia Larentis
 * http://www.apache.org/licenses/LICENSE-2.0
 */
.bootstrap-switch {
  width: 86px !important;
  min-width: 86px !important;
}

.bootstrap-switch-handle-on, .bootstrap-switch-handle-off {
  width: 72px !important;
  min-width: 50% !important;
}
.bootstrap-switch-off .bootstrap-switch-handle-on { 
  min-width: 50% !important;
}
.bootstrap-switch-off .bootstrap-switch-handle-off {
  min-width: 70% !important;
}


.bootstrap-switch-on .bootstrap-switch-handle-on { 
  min-width: 70% !important;
}
.bootstrap-switch-on .bootstrap-switch-label {
  left: 78% !important;
}