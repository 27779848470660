.new-fee-modal, .password-reset-modal {
  .modal-content .modal-body {
    .fee_settings_modal__heading {
      text-align: left;
    }
    .modal-body {
      padding: 0;
      button .my-4.btn-secondary {
        margin: 1rem 0;
      }
    }
  }
}
