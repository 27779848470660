.p-detail-flex.orders {
  .MuiCheckbox-root.Mui-disabled {
    opacity: 0.3;
  }
.MuiInputBase-root.orders-filter-by {
  padding-left: 10px;
  padding-top: 5px;
}
.empty-state-card {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}

}
