.pending-code-container, .enterprise-consignment-container, .individual-consignment-container, .copyt-card {
  width: 98%;
  margin: 10px;
  .card-header {
    .card-text {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      color: #390533;
      font-size: 1.1rem !important;

      .toggle-application-button {
        .MuiButtonBase-root {
          padding: 9px;
          position: absolute;
        }
      }
    }
  }
}

.card-layout {
  max-width: 1600px;
  min-width: 200px;
  margin-bottom: 0;
  padding: 5px 10px 5px 0;
}

