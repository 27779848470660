.dropdownMenu_list {
  background-color: white;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  position: absolute;
  border-radius: 8px;
  box-shadow: 1px -2px 45px -21px rgba(0, 0, 0, 0.75);
  z-index: 1;

  &__item {
    cursor: pointer;
    padding: 10px 30px;
    font-size: 14px;
    font-weight: bold;
    color: #5d0953;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    transition: all 0.2s ease-in-out;

    &:not(:last-child) {
      border-bottom: 1px solid #00000010;
    }

    &:hover {
      background-color: #00000005;
      color: #d5239e;
    }
  }
}
