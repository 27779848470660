.table_header {
  background-color: #d5239e;
  padding: 20px 20px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  &__content {
    flex-direction: column;
    position: relative;
    display: flex;
    gap: 20px;
    justify-content: space-between;

    @media (min-width: 992px) {
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
