.copytPro-modal {
  .modal-body {
    background-color: #efe7f0;

    .copytPro-modal-header {
      color: #000;
      font-weight: 800;
      font-size: 2.5rem;
      line-height: 3rem;
      text-align: center;
      span {
        color: #d5239e;
      }
    }
    p.header {
      margin: 2rem 0;
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 2rem;
      text-align: center;
    }

    .Mui-error {
      color: red;
      text-align: left;
      font-size: 1rem;
      line-height: 1rem;
    }

    .nextBtn {
      background-color: #d5239e;
    }
  }
}
