.search_input__container {
  position: relative;
  color: black;
  cursor: pointer;

  svg {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.search_input {
  height: 40px;
  padding: 0 20px;
  padding-right: 60px;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  width: 100%;
}
