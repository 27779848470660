@import 'copyt-platform-ui/custom/theme';

body {
    touch-action: pan-x pan-y;
}
.inventory-tab-div {
    display: flex;
    flex-direction: row;
}

.inventory-tab-selected {
    background: $theme-primary;
    border: none;
    padding: 8px 24px;
    color: $theme-background;
    outline: none;
    box-shadow: $theme-box-shadow;
}

.inventory-tab-not-selected {
    box-shadow: $theme-box-shadow;
    background: $theme-white;
    padding: 8px 24px;
    border: none;
    color: $theme-primary;
    outline: none;
}

.inventory-header-tr {
    border-bottom: 2px solid $theme-black;
}

.inventory-item-tr {
    box-sizing: border-box;
    height: 96px;
    max-height: 96px;
    border-bottom: 1px solid $theme-black;
}

.inventory-item-tr td {
    max-width: 264px;
    border-top: unset !important;
}

.impromptu-header {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    padding: 12px;
}

.mobile-inventory-search .form-group {
    flex: 1;
    margin-right: 8px;
}

.impromptu-header .form-group {
    margin-bottom: 0;
}

.impromptu-header .import-button {
    color: $theme-black;
    width: 24px;
    height: 24px;
    min-height: 24px;
    min-width: 24px;
    margin: 8px;
    padding: 0;
}

.export-btn {
    margin-right: 16px;
}

.generate-barcodes-btn {
    margin-right: 16px;
}

.process-all-btn {
    margin-left: 16px;
}

.inventory-header-th {
    color: rgba($theme-secondary, 0.56) !important;
    padding: 0 8px !important;
}

.mobile-row {
    justify-content: flex-end;
    .inventory-header-th {
        color: rgba($theme-secondary, 0.56) !important;
        padding: 0 !important;
    }
}

.content {
    display: flex;
    flex-direction: column;
    padding: 20px 24px 24px;
}

.input-icon-prefix {
    position: absolute;
    top: 6px;
    left: 6px;
    color: $theme-black;
}

.input-icon-prefix + .form-control {
    padding: 10px 18px 10px 32px !important;
}

.filter-menu {
    padding: 16px !important;
    display: flex !important;
    flex-direction: column;
    min-width: 184px !important;
    max-height: 70vh;
    overflow-y: auto;
    background-color: $theme-background !important;
}

.filter-menu::-webkit-scrollbar {
    width: 10px;
}

.filter-menu::-webkit-scrollbar-track {
    background-color: $theme-background;
}

.filter-menu::-webkit-scrollbar-thumb {
    background-color: $theme-secondary;
    border-radius: 10px;
    border: 2px solid $theme-background;
}

.filter-menu-header {
    color: $theme-secondary;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
}

.filter-menu-header:not(:first-child) {
    margin-top: 16px;
}

.filter-menu-toggle {
    background-color: transparent;
    margin: 0;
    padding: 0;
}

.filter-menu-item {
    font-size: 14px;
    padding: 0 8px;
    cursor: pointer;
}

.filter-menu-item:hover {
    color: $theme-primary;
}

.filter-menu-item .custom-checkbox + span {
    width: 16px;
    height: 16px;
}

.mobile-inventory-header {
    display: flex;
    justify-content: flex-end;
}

.mobile-invetory-search-add {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    .mobile-search-container {
        height: 40px;
        max-width: 432px;
        width: 72%;
        margin-right: 16px;
    }
    .mobile-button {
        margin-bottom: 16px;
        max-width: 160px;
    }
}

.mobile-inventory-tab-div {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
    box-shadow: $theme-box-shadow;
    background: #ffffff;
    border-radius: 56px;
    height: 40px;
    font-size: 12px;
    padding: 0 2px;
    .col {
        padding-left: 0;
        padding-right: 0;
        flex: 1;
        margin: 0 1px;
        .mobile-inventory-tab-selected {
            width: 100%;
            z-index: 1;
            background: $theme-primary;
            border: none;
            color: $theme-background;
            outline: none;
            box-shadow: $theme-box-shadow;
            border-radius: 56px;
            height: 36px;
            margin-top: 1px;
            padding: 0;
        }

        .mobile-inventory-tab-not-selected {
            width: 100%;
            background: $theme-white;
            border: none;
            color: $theme-primary;
            outline: none;
            border-radius: 56px;
            height: 36px;
            margin-top: 2px;
            margin-left: 2px;
        }
    }
    .mobile-listing-tab-container {
        margin-left: 2px;
        margin-top: 2px;
        width: 31%;
        height: 36px;
        margin-right: 3%;
        .mobile-inventory-tab-selected {
            background: $theme-primary;
            border: none;
            color: $theme-background;
            outline: none;
            box-shadow: $theme-box-shadow;
            border-radius: 56px;
            width: 100%;
            height: 36px;
        }
        .mobile-inventory-tab-not-selected {
            background: $theme-white;
            border: none;
            color: $theme-primary;
            outline: none;
            border-radius: 56px;
            width: 100%;
            height: 36px;
        }
    }
}

.mobile-button {
    width: 98% !important;
    margin-bottom: 16px;
}
.mobile-card {
    height: fit-content;
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    color: $theme-black;
    margin-bottom: 16px !important;
    .mobile-card-body {
        padding: 0px !important;
        max-height: 100%;
        .mobile-card-header {
            font-weight: 500;
            font-size: 12px;
            border-bottom: solid 1px $theme-muted;
            height: 64px;
            max-height: 64px;
            box-sizing: border-box;
            .mobile-card-header-padding {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 100%;
                padding: 4px 8px;
                .mobile-card-header-image {
                    margin-top: 4px;
                    width: 40px;
                    height: 40px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center center;
                }
                .inventory-header-details {
                    display: flex;
                    flex-direction: column;
                    overflow: hidden;
                }
                .inventory-header-th {
                    padding: 0 8px 0 8px !important;
                    color: $theme-black !important;
                    font-weight: 600;
                    font-size: 18px;
                    p {
                        font-weight: 500;
                    }
                }
                .inventory-header-td {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    min-width: 0;
                    white-space: nowrap;
                    padding: 0 8px 0 8px !important;
                }
            }
        }
        .mobile-card-body-table-padding {
            padding: 6px 11px 11px 14px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            .inventory-header-th {
                color: $theme-secondary !important;
                padding: 5px 30px 0 0 !important;
                font-weight: 600;
                font-size: 14px;
                display: flex;
                flex-direction: column;
                width: 50%;
                p {
                    font-weight: 500;
                }
            }
        }
    }
}
.mobile-button-empty {
    margin-left: auto;
    margin-right: auto;
    width: 128px !important;
}
.empty-state-card {
    width: 100%;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .empty-state-svg {
        width: 100%;
        margin-top: 53px;
    }
    .empty-state-text {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.09px;
        color: $theme-secondary;
        text-align: center;
        //max-width: 288px;
    }
    .empty-state-header {
        color: $theme-secondary;
        font-weight: 500;
        margin: 10px 0px;
    }
    .empty-state-body {
        color: $theme-secondary;
        font-size: 12px;
        font-weight: 400;
    }
}

.date-range-container,
.date-range-container-mobile {
    display: flex;
    flex-direction: row;

    .date-container {
        display: flex;
        flex-direction: row;
        align-content: center;

        .date-label {
            margin: auto 8px;
        }
    }
}

.date-range-container-mobile {
    .react-datepicker-wrapper {
        width: 50%;
        margin: 8px;
    }
    input {
        width: 100%;
    }
}

.date-range-container {
    margin-right: 16px;
}

.import-modal {
    .import-btn {
        position: relative;
        width: 240px;
        margin: 8px 32px;
    }
    .modal-body {
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .form-control-file {
        opacity: 0;
        cursor: pointer;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .file-type {
        text-align: center;
        margin: 8px 24px;
    }

    .close-btn {
        position: absolute;
        top: 16px;
        right: 16px;
    }
}

.btn-secondary + .btn-secondary {
    margin-left: 16px;
}
@media (max-width: 640px) {
    .import-modal {
        .btn-primary {
            width: 80% !important;
        }
    }
    .export-btn {
        margin-bottom: 16px;
        margin-right: 0px;
    }

    .export-import {
        display: flex;
        width: 100%;
        justify-content: right;
        align-content: center;
    }
    .import-button {
        color: #140010 !important;
        margin-bottom: auto;
    }
    .process-all-btn {
        margin-left: 16px;
    }
}
